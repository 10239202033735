import {
    trigger,
    animate,
    transition,
    style,
    query,
    group,
    useAnimation,
    animation,
    sequence
  } from '@angular/animations';
  
  export const fade = trigger('fade', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({ position: 'absolute', width: '100%', opacity: 0 })
      ], { optional: true }),
      query(':enter', [
        animate('1600ms ease-in', style({ opacity: 1 }))
      ], { optional: true }),
      query(':leave', [
        animate('1600ms ease-out', style({ opacity: 0 }))
      ], { optional: true })
    ])
  ])

  export const fade2 = trigger('fade2', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({ position: 'absolute', width: '100%', opacity: 0 })
      ], { optional: true }),
      query(':enter', [
        style({ transform: 'scale(0.8)' }),
        group([
          animate('1000ms ease', style({ opacity: 1 })),
          animate('1000ms ease', style({ transform: 'scale(1)' }))
        ])
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'scale(1)' }),
        group([
          animate('1000ms ease', style({ opacity: 0 })),
          animate('1000ms ease', style({ transform: 'scale(0.8)' }))
        ])
      ], { optional: true })
    ])
  ])

  export const fade3 = trigger('fade3', [
    transition('* <=> *', [
      sequence([
        group([
          query(':leave', [
            style({ opacity: 1 }),
            animate('2s', style({ opacity: 0 }))
          ], { optional: true }),
          query('.circle-container', [
            style({ opacity: 0 }),
            animate('2s', style({ opacity: 1 }))
          ], { optional: true })
        ]),
        query('.circle-container', [
          animate('2s', style({ background: 'radial-gradient(circle, black 1%, transparent 20%)' }))
        ], { optional: true }),
        group([
          query(':enter', [
            style({ opacity: 0 }),
            animate('2s', style({ opacity: 1 }))
          ], { optional: true }),
          query('.circle-container', [
            style({ background: 'radial-gradient(circle, black 1%, transparent 20%)' }),
            animate('2s', style({ background: 'transparent' }))
          ], { optional: true })
        ])
      ])
    ])
  ])

  export const fade4 = trigger('fade4', [
    transition('* <=> *', [
      group([
        query(':enter, :leave', [
          style({ position: 'absolute', width: '100%', height: '100%', opacity: 1 }),
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          animate('5s ease', style({ opacity: 1 })),
        ], { optional: true }),
        query('.circle-overlay', [
          style({ opacity: 1 }),
          animate('2s ease', style({ opacity: 0 })),
        ], { optional: true }),
      ]),
    ]),
  ])

  export const fadeAnimation = trigger('fadeAnimation', [
    transition('* => *', [
      query(
        ':enter',
        [style({ opacity: 0 })],
        { optional: true }
      ),
      query(
        ':leave',
        [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
        { optional: true }
      ),
      query(
        ':enter',
        [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
        { optional: true }
      )
    ])
  ]);
  
  export const routerTransition = trigger('routerTransition', [
    transition('* <=> *', [  
      query(':enter, :leave', style({ position: 'fixed', width:'100%' }), { optional: true }),
      group([ 
        query(':enter', [
          style({ transform: 'translateX(100%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
          ], { optional: true}),
        //   style({ transition: 'transform 2.5s ease' }),
        //   animate('0.5s ease-in-out', style({ transform: 'rotate(360deg)' }))]),
      ])
    ])
  ])

// reusable animations transform: 'rotate(360deg)' transform: 'translateX(-100%)'
  export const transitionAnimation = animation([
    style({
      height: '{{ height }}',
      opacity: '{{ opacity }}',
      backgroundColor: '{{ backgroundColor }}'
    }),
    animate('{{ time }}')
  ]);
  
  export const triggerAnimation = trigger('openClose', [
    transition('open => closed', [
      useAnimation(transitionAnimation, {
        params: {
          height: 0,
          opacity: 1,
          backgroundColor: 'red',
          time: '10s'
        }
      })
    ])
  ]);

  export const fadeSlide = trigger('fadeSlide', [
    transition(':enter', [
      query('.item:nth-child(odd)', [
        style({ opacity: 0, transform: 'translateX(-250px)' }),
        animate(
          1000,
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ], { optional: true}),
      query('.item:nth-child(even)', [
        style({ opacity: 0, transform: 'translateX(250px)' }),
        animate(
          1000,
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ], { optional: true })
    ]),
    transition(':leave', [
      query('.item:nth-child(odd)', [
        animate(
          1000,
          style({ opacity: 0, transform: 'translateX(-250px)' })
        )
      ], { optional: true }),
      query('.item:nth-child(even)', [
        animate(
          1000,
          style({ opacity: 0, transform: 'translateX(250px)' })
        ),
      ], { optional: true })
    ])
  ])

  // https://stackblitz.com/angular/qmpjdxkexvd?file=src%2Fapp%2Fapp.component.ts
  